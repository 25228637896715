<template>
  <WeContainer card="">
    <div class="card">
      <div class="card-header pt-4 pb-4 custom-header-bg">
        <h5 class="font-weight-bold mb-0 text-danger">
          <span class="text-uppercase">SİLİNEN KATEGORİLER</span>
        </h5>
      </div>
      <div class="card-body position-relative" style="min-height: 200px">
        <WeTable
          v-if="ready"
          v-bind:index="false"
          v-bind:data.sync="category.list"
          v-bind:columns="columns"
          v-bind:actions="tableActions"
          v-on:on-action="onAction"
        ></WeTable>
        <WeLoading v-else />
      </div>
    </div>
  </WeContainer>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  name: "Trash",
  data() {
    return {
      ready: false,
      tableActions: [
        {
          icon: "fas fa-trash",
          class: "btn-outline-danger",
          childrenText: "Alt Kategori",
          action: "children",
        },
        {
          icon: "fas fa-sync fa-sm",
          class: "btn-outline-primary ml-2",
          action: "restore",
          tooltip: "Geri Al",
        },
        {
          icon: "fas fa-trash fa-sm",
          class: "btn-outline-danger ml-2",
          action: "remove",
          tooltip: "Kalıcı Olarak Sil",
        },
      ],
      columns: [
        { name: "image", icon: "fas fa-camera", type: "image", width: "5%" },
        { name: "full_name", th: "Kategori Adı", type: "string" },
        {
          name: "marketplace_list",
          th: "Pazaryeri",
          type: "marketplace_list",
        },
        {
          name: "is_showcase",
          th: "Vitrinde Göster",
          type: "boolean_disabled",
          width: "10%",
        },
        {
          name: "is_showmenu",
          th: "Menüde Göster",
          type: "boolean_disabled",
          width: "10%",
        },
        {
          name: "is_active",
          th: "Durum",
          type: "boolean_disabled",
          width: "10%",
        },
      ],
    };
  },
  methods: {
    ...mapActions("category", ["getList", "restore", "remove"]),
    onAction(data) {
      if (data.key == "remove") {
        this.onRemove(data.row, data.index);
      } else if (data.key == "restore") {
        this.onRestore(data.row, data.index);
      } else if (data.key == "children") {
        this.onChildren(data.row);
      }
    },
    onChildren(row) {
      const parent = {
        id: row.id,
        name: row.name,
      };
      this.category.breadCrumb.push(parent);

      this.$router.replace("/categories/trash/children/" + row.id);
    },
    onRestore(row, index) {
      let message = `${row.name} adlı kategoriyi geri almak istediğinize emin misiniz ?`;
      this.$swal({
        title: message,
        icon: "question",
        showCancelButton: true,
        cancelButtonText: "İptal",
        confirmButtonText: "Geri Al",
      }).then((confirm) => {
        if (confirm.isConfirmed) {
          const checkPermission = this.checkPermission("r");
          if (!checkPermission) {
            permission.alert(this);
          } else {
            this.restore({
              id: row.id,
              onSuccess: (result) => {
                if (result && result.data && result.data.status !== "error") {
                  this.$toast.success("Kategori Başarıyla Geri Alındı");
                  this.getAll();
                } else {
                  this.$swal({
                    title: "Kategori Geri Alınamadı",
                    text: result.data.message,
                    icon: "error",
                    confirmButtonText: "Tamam",
                  });
                }
              },
            });
          }
        }
      });
    },
    onRemove(row, index) {
      let message = `${row.name} kategorisini kalıcı olarak silmek istediğinize emin misiniz ?`;
      this.$swal({
        title: message,
        text: "Bu işlem geri alınamaz.",
        icon: "question",
        showCancelButton: true,
        cancelButtonText: "İptal",
        confirmButtonText: "Sil",
      }).then((confirm) => {
        if (confirm.isConfirmed) {
          const checkPermission = this.checkPermission("f");
          if (!checkPermission) {
            permission.alert(this);
          } else {
            this.remove({
              id: row.id,
              onSuccess: (result) => {
                if (result && result.data && result.data.status !== "error") {
                  this.$toast.success("Kategori Başarıyla Silindi");
                  this.getAll();
                } else {
                  this.$swal({
                    title: "Kategori Silinemedi",
                    text: result.data.message,
                    icon: "error",
                    confirmButtonText: "Tamam",
                  });
                }
              },
            });
          }
        }
      });
    },
    getAll() {
      this.ready = false;
      this.getList({
        id: this.$route.params.id,
        deleted: true,
        onSuccess: (result) => {
          this.ready = true;
        },
      });
    },
    clearBreadCrumb() {
      const id = this.$route.params.id;
      if (!id) {
        this.category.breadCrumb = [];
      } else {
        let breadCrumb = this.category.breadCrumb;
        if (breadCrumb && breadCrumb.length) {
          const lastElement = breadCrumb[breadCrumb.length - 1];
          if (lastElement.id !== id) {
            breadCrumb.pop();
          }
        }
      }
    },
    checkPermission(code) {
      return permission.check("category", code);
    },
  },
  computed: {
    ...mapState(["category", "shared","session"]),
  },
  mounted() {
    this.getAll();
    this.clearBreadCrumb();
    let locales = this.session?.config["site.available_locales"];
    if(locales != undefined){
      locales = JSON.parse(locales)
      if(locales.length > 1) this.columns.push({ name: "locales", th: "Dil", type: "language"})
    }
  },
};
</script>
